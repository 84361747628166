<template>
    <div>
        <div class="uk-card product-card uk-card-default uk-overflow-hidden"
            v-if="itemSize == 'regular'">
            <div class="uk-card-media-top uk-overflow-hidden">
                <img class="uk-responsive-width" src="images/products/product_small_sample_fill.jpg" alt="">
            </div>
            <div class="uk-card-body uk-padding-small uk-padding-remove-vertical ">
                <div class="product-info-wrapper  uk-margin-small-top ">
                    <h3 class="uk-card-title uk-margin-small-bottom">Iphone 12 X</h3>
                    <div class="product-meta">
                        <p>Apple flagship device, with 55MPX camera</p>
                    </div>
                    <div class="product-prices">
                        <div class="current-price uk-margin-small-right uk-text-primary uk-text-bold uk-text-large">2 233 F</div>
                        <div class="old-price ">2 500 F</div>
                    </div>
                    <div class="product-promotions uk-padding-small uk-padding-remove-horizontal">
                        <div class="loyalty-discount uk-background-secondary uk-badge uk-padding-small uk-display-block" v-if="highlight == 'loyalty-bonus'">
                            Get 5 000 loyalty
                        </div>
                        <div class="loyalty-discount uk-background-secondary uk-badge uk-padding-small uk-display-block" v-if="highlight == 'loyalty-discount'">
                            for 3 500 loyalty
                        </div>
                    </div>
                    <div class="product-cta uk-card-footer uk-padding-remove-horizontal">
                        <form-button :fullWidth="true" icon="cart" @click="$emit('addToCart')">ADD TO CART</form-button>
                    </div>
                </div>
            </div>
        </div>

        <div class="uk-card product-card product-card--large uk-card-default uk-overflow-hidden"
             v-if="itemSize == 'large'">
            <div class="uk-card-media-top uk-overflow-hidden">
                <img class="uk-responsive-width" src="images/products/product_small_sample_fill.jpg" alt="">
            </div>
            <div class="uk-card-body uk-padding-small uk-padding-remove-vertical ">
                <div class="product-info-wrapper  uk-margin-small-top ">
                    <h3 class="uk-card-title uk-margin-small-bottom">Iphone 12 X</h3>
                    <div class="product-meta">
                        <p>Apple flagship device, with 55MPX camera</p>
                    </div>
                    <div class="product-prices">
                        <div class="current-price uk-margin-small-right uk-text-primary uk-text-bold uk-text-large">2 233 F</div>
                        <div class="old-price ">2 500 F</div>
                    </div>
                    <div class="product-promotions uk-padding-small uk-padding-remove-horizontal">
                        <div class="loyalty-discount uk-background-secondary uk-badge uk-padding-small uk-display-block" v-if="highlight == 'loyalty-bonus'">
                            Get 5 000 loyalty
                        </div>
                        <div class="loyalty-discount uk-background-secondary uk-badge uk-padding-small uk-display-block" v-if="highlight == 'loyalty-discount'">
                            for 3 500 loyalty
                        </div>
                    </div>
                    <div class="product-cta uk-card-footer uk-padding-remove-horizontal">
                        <form-button :fullWidth="true" icon="cart" @click="$emit('addToCart')">ADD TO CART</form-button>
                    </div>
                </div>
            </div>
        </div>

        <div class="uk-card product-card product-card--micro uk-card-default uk-overflow-hidden"
             v-if="itemSize == 'micro'">
            <div class="uk-flex">
                <div class="uk-card-media-top uk-overflow-hidden">
                    <img class="uk-responsive-width" src="images/products/product_small_sample_fill.jpg" alt="">
                </div>
                <div class="uk-card-body uk-padding-small uk-padding-remove-vertical ">
                    <div class="product-info-wrapper  uk-margin-small-top ">
                        <h3 class="uk-card-title uk-margin-small-bottom">Iphone 12 X</h3>
                        <div class="product-meta uk-overflow-auto">
                            <p>Apple flagship device, with 55MPX camera</p>
                        </div>

                    </div>
                </div>
            </div>


            <div class="cta-area uk-flex uk-padding-small">
                <div class="product-prices">
                    <div class="current-price uk-margin-small-right uk-text-primary uk-text-bold uk-text-small">2 233 F</div>
                    <div class="old-price uk-text-small uk-margin-small-right">2 500 F</div>
                </div>

                <div class="product-cta uk-width-expand uk-padding-remove-horizontal">
                    <form-button size="small" :fullWidth="true" icon="cart" @click="$emit('addToCart')">ADD</form-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            itemSize: {
                type: String,
                default: 'regular',
            },
            /**
             * Prop for demo porpuses
             */
            highlight: {
                type: String,
                default: 'loyalty-bonus'
            }
        },
        emits: [
          'addToCart'
        ],
        data: function () {
            return {};
        }

    }
</script>

<style scoped lang="scss">
    .product-card {
        display: flex;
        flex-direction: column;
        flex: 0 0 200px;
        width: 200px;

        .uk-card-media-top {
            img {

            }
        }

        .uk-card-body {
            display: flex;
            flex-direction: column;
        }

        .loyalty-discount {
            display: flex;
            align-items: center;
            height: auto;
            padding-top: calc(var(--global-margin) * 0.2);
            padding-bottom: calc(var(--global-margin) * 0.2);
            border-radius: var(--border-radius-small);

        }

        .product-info-wrapper {
            display: flex;
            flex-direction: column;

            .product-cta{
                margin-top: auto;
            }
        }

        .uk-card-media-top {
            width: 100%;
            height: 150px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                max-height: 100%;
                max-width: 100%;
                width: 100%;
                height: auto;
                margin: auto;

            }
        }

        .old-price {
            position: relative;
            top: -4px;
            text-decoration: line-through;

        }

        &.product-card--large {
            flex: 0 0 350px;
            width: 350px;

            .uk-card-media-top {
                width: 100%;
                height: 350px;


                img {


                }
            }
        }

        &.product-card--micro {
            display: block;
            height: 175px;

            .uk-card-media-top {
                height: auto;
            }

            .product-meta {
                max-height: 90px;
            }

            .product-info-wrapper {
                margin: 0;
                p {
                    margin: 0;
                }
            }
            .uk-card-media-top {
                img {
                    margin-top: 0;
                }
            }

            .uk-card-title {
                font-size: calc(var(--global-font-size) * 1);
            }

            .product-meta {
                font-size: calc(var(--global-font-size) * 0.75);
            }

            .old-price {
                font-size: calc(var(--global-font-size) * 0.75);

            }
        }
    }
</style>
