<template>
    <div class=" uk-position-relative  " uk-slider>
        <div class="uk-slider-container uk-container uk-container-large"
        :class="{'uk-padding-remove' : removePadding}">
            <ul class="upsales uk-flex uk-slider-items uk-overflow-visible"
            :class="listClass">
                <product-upsale
                        @addToCart="$emit('addToCart')"
                        :highlight="highlight"
                        class="uk-margin-small-right uk-margin-small-left"
                        :product="{}"
                        :item-size="itemSize"
                        v-for="test in itemCount"></product-upsale>

            </ul>
        </div>


        <a class="uk-position-center-left-out" href="#" @click.prevent="" uk-slidenav-previous uk-slider-item="previous"></a>
        <a class="uk-position-center-right-out" href="#" @click.prevent="" uk-slidenav-next uk-slider-item="next"></a>

    </div>
</template>

<script>
    import ProductUpsale from "@/client/components/ecommerce/products/ProductUpsale.vue";
    export default {
        props: {
            itemSize: {
                type: String,
                default: 'regular',
            },
            itemCount: {
                type: Number,
                default: 12
            },
            alignment: {
                type: String,
                default: 'row'
            },
            /**
             * Prop for demo porpuses
             */
            highlight: {
                type: String,
                default: 'loyalty-bonus'
            },
            removePadding: {
                type: Boolean,
                default: false
            }
        },
        data: function () {
            return {};
        },
        emits: [
          'addToCart'
        ],
        components: {
            ProductUpsale
        },
        computed: {
            listClass() {
                let result = [];

                switch (this.alignment) {
                    case 'center':
                        result.push('uk-flex-center');
                        break;
                    case 'row-reverse':
                        result.push('uk-flex-row-reverse');
                        break;
                    case 'row':
                    default:
                        break;
                }
                return result;
            }
        }

    }
</script>

<style scoped lang="scss">

</style>
